import React from 'react';
import fileIcon from '../../assets/attachFile.png';
import sendBtn from '../../assets/send.svg';

const ChatInput = ({
  input,
  setInput,
  isLoading,
  onSend,
  onUpload,
  onEnter
}) => {
  return (
    <div className="chat-input-area">
      <div className="inp">
        <label htmlFor="fileInput">
          <img src={fileIcon} alt="file" className="fileAdd" />
          <input
            type="file"
            multiple
            id="fileInput"
            style={{ display: "none" }}
            onChange={onUpload}
            disabled={isLoading}
          />
        </label>
        <input
          type="text"
          placeholder={
            isLoading
              ? "Please wait for the response..."
              : "Send a message"
          }
          value={input}
          onChange={(e) => setInput(e.target.value)}
          onKeyDown={(e) => {
            if (e.key === "Enter" && !isLoading) onSend();
          }}
        />
        <button
          className="send"
          onClick={onSend}
          disabled={input.trim() === "" || isLoading}
        >
          <img src={sendBtn} alt="send" />
        </button>
      </div>
    </div>
  );
};

export default ChatInput;