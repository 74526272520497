import { useState, useEffect } from "react";
import { getCurrentUser, signOut } from "aws-amplify/auth";

export const useAuth = () => {
  const [userName, setUserName] = useState("");
  const [userEmail, setUserEmail] = useState("");

  const fetchUserInfo = async () => {
    //**useAuth.js
    try {
      const { username, signInDetails } = await getCurrentUser();
      setUserName(username);
      if (signInDetails && signInDetails.loginId) {
        setUserEmail(signInDetails.loginId);
      }
    } catch (error) {
      console.error("Error fetching user info: ", error);
    }
  };

  const handleLogout = async () => {
    try {
      await signOut();
    } catch (error) {
      console.error("Error signing out:", error);
    }
  };

  useEffect(() => {
    fetchUserInfo();
  }, []);

  return {
    userName,
    userEmail,
    handleLogout,
    fetchUserInfo,
  };
};
