import { useState, useRef, useEffect } from "react";
import axios from "axios";

export const useChat = (sessionToken, apiBaseUrl, initiateSession, userId) => {
  const [messages, setMessages] = useState([
    {
      text: "Hi there! How can I help you?",
      isBot: true,
      diagnosis: null,
      actionSteps: [],
    },
  ]);
  const [input, setInput] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isSendingMessage, setIsSendingMessage] = useState(false);
  const [showDefaultOptions, setShowDefaultOptions] = useState(true);
  const [diagnosis, setDiagnosis] = useState("");
  const [actionSteps, setActionSteps] = useState([]);
  const [showNextButton, setShowNextButton] = useState(false);
  const [currentStepIndex, setCurrentStepIndex] = useState(0);
  const [files, setFiles] = useState([]);
  const [currentLoadingPrompt, setCurrentLoadingPrompt] = useState("");
  const [loadingPromptInterval, setLoadingPromptInterval] = useState(null);
  const [techLevels, setTechLevels] = useState({});
  const isProgrammaticChange = useRef(false);
  const msgEnd = useRef(null);

  const mainLoadingPrompts = [
    "Analyzing query and context",
    "Accessing knowledge base",
    "Processing relevant information",
    "Synthesizing response",
    "Optimizing output for user comprehension",
    "Finalizing response",
  ];

  const sendMsgToOpenAI = async (msg) => {
    setIsSendingMessage(true);
    if (!sessionToken) {
      console.error("No session token available");
      return {
        text: "I'm sorry, but there seems to be an issue with your session. Please try logging out and back in.",
        diagnosis: null,
        actionSteps: [],
      };
    }

    setIsLoading(true);
    cycleLoadingPrompts(); // Start cycling through loading prompts

    const maxRetries = 3;
    const baseDelay = 1000; // 1 second

    for (let attempt = 0; attempt < maxRetries; attempt++) {
      try {
        const response = await axios.post(
          `${apiBaseUrl}/send_message_to_genai`,
          { message: msg },
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${sessionToken}`,
            },
          }
        );
        const data = response.data;
        isProgrammaticChange.current = true;
        setTechLevels(data.tech_level);

        // Parse the JSON response
        let parsedResponse = {
          text: data.response,
          diagnosis: null,
          actionSteps: [],
        };

        try {
          const jsonResponse = JSON.parse(data.response);
          parsedResponse = {
            text: jsonResponse.response || data.response,
            diagnosis: jsonResponse.diagnosis || null,
            actionSteps: jsonResponse.action_steps || [],
          };
        } catch (parseError) {
          console.error("Failed to parse JSON response:", parseError);
        }

        stopLoadingPrompts(); // Stop cycling through loading prompts
        setIsLoading(false);

        // Update state based on parsed response
        setDiagnosis(parsedResponse.diagnosis);
        setActionSteps(parsedResponse.actionSteps);
        setShowNextButton(parsedResponse.actionSteps.length > 0);
        setCurrentStepIndex(0);

        return parsedResponse;
      } catch (error) {
        console.error(`Attempt ${attempt + 1} failed:`, error);

        if (error.response) {
          if (error.response.status === 401) {
            // Token might be expired, initiate a new session
            await initiateSession(userId);
            continue; // Try again with the new session
          } else if (
            error.response.status === 503 ||
            error.response.data.message === "Service Unavailable"
          ) {
            if (attempt === maxRetries - 1) {
              // If this was the last attempt, throw the error to be caught outside
              throw error;
            }
            // Wait before retrying
            await new Promise((resolve) =>
              setTimeout(resolve, baseDelay * Math.pow(2, attempt))
            );
            continue; // Try again
          }
        }

        // For other errors, or if we've exhausted our retries, throw the error
        throw error;
      } finally {
        setIsSendingMessage(false);
      }
    }

    // If we've exhausted all retries
    stopLoadingPrompts();
    setIsLoading(false);
    return {
      text: "I'm sorry, but I'm having trouble responding right now. Please try again later.",
      diagnosis: null,
      actionSteps: [],
    };
  };
  const handleSendMsg = async () => {
    const text = input.trim();
    if (text === "" || isLoading) return;

    setInput("");
    setShowDefaultOptions(false);
    setIsLoading(true);
    setMessages((prevMessages) => [
      ...prevMessages,
      { text, isBot: false, diagnosis: null, actionSteps: [] },
    ]);

    try {
      const res = await sendMsgToOpenAI(text);
      setMessages((prevMessages) => [
        ...prevMessages,
        {
          text: res.text,
          isBot: true,
          diagnosis: res.diagnosis,
          actionSteps: res.actionSteps,
        },
      ]);
    } catch (error) {
      console.error("Failed to send message:", error);
      setMessages((prevMessages) => [
        ...prevMessages,
        {
          text: "I'm sorry, but I'm having trouble responding right now. Please try again in a moment.",
          isBot: true,
          diagnosis: null,
          actionSteps: [],
        },
      ]);
    } finally {
      setIsLoading(false);
      stopLoadingPrompts();
    }

    setFiles([]);
  };

  const handleDefaultOption = async (option) => {
    setInput("");
    setShowDefaultOptions(false);

    const userMessage = {
      text: option,
      isBot: false,
      diagnosis: null,
      actionSteps: [],
    };

    setMessages((prevMessages) => [...prevMessages, userMessage]);

    try {
      setIsLoading(true);
      const response = await sendMsgToOpenAI(option);
      setIsLoading(false);

      const botMessage = {
        text: response.text,
        isBot: true,
        diagnosis: response.diagnosis,
        actionSteps: response.actionSteps,
      };

      setMessages((prevMessages) => [...prevMessages, botMessage]);
    } catch (error) {
      console.error("Error fetching chat response:", error);
      setIsLoading(false);
      setMessages((prevMessages) => [
        ...prevMessages,

        {
          text: "Sorry, I couldn't process that request. Please try again.",
          isBot: true,
          diagnosis: null,
          actionSteps: [],
        },
      ]);
    }
  };

  const cycleLoadingPrompts = () => {
    let currentIndex = 0;
    setCurrentLoadingPrompt(mainLoadingPrompts[currentIndex]);

    const interval = setInterval(() => {
      currentIndex++;
      if (currentIndex < mainLoadingPrompts.length) {
        setCurrentLoadingPrompt(mainLoadingPrompts[currentIndex]);
      } else {
        // Keep the last prompt for a moment before stopping
        setTimeout(() => {
          if (loadingPromptInterval) {
            clearInterval(loadingPromptInterval);
            setLoadingPromptInterval(null);
          }
        }, 1000); // Show the last prompt for 1 second
        clearInterval(interval);
      }
    }, 5000); // Change prompt

    setLoadingPromptInterval(interval);
  };

  const stopLoadingPrompts = () => {
    if (loadingPromptInterval) {
      clearInterval(loadingPromptInterval);
      setLoadingPromptInterval(null);
    }
    setCurrentLoadingPrompt("");
  };

  return {
    messages,
    setMessages,
    input,
    setInput,
    isLoading,
    showDefaultOptions,
    setShowDefaultOptions,
    diagnosis,
    actionSteps,
    showNextButton,
    currentStepIndex,
    files,
    setFiles,
    currentLoadingPrompt,
    techLevels,
    setTechLevels,
    handleSendMsg,
    handleDefaultOption,
    sendMsgToOpenAI,
    msgEnd,
  };
};
