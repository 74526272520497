import React, { useState, useRef, useEffect } from "react";
import {
  User,
  ChevronDown,
  ChevronUp,
  Type,
  LogOut,
  Fingerprint,
  Bell,
  X,
} from "lucide-react";
import CopyableIdBox from "./CopyableIdBox";

const UserMenu = ({
  userEmail,
  onLogout,
  onRejectClueCollector,
  associatedId,
  isConnected,
  onFontChange,
  checkAssociatedCid,
  hasRecommendation,
  recommendationContent,
  isOpen,
  setIsOpen
}) => {
  const [isFontMenuOpen, setIsFontMenuOpen] = useState(false);
  const [isRecommendationOpen, setIsRecommendationOpen] = useState(false);
  const menuRef = useRef(null);
  const recommendationRef = useRef(null);

  const toggleUserMenu = () => {
    setIsOpen(!isOpen);
    setIsFontMenuOpen(false);
  };

  const toggleFontMenu = (e) => {
    e.stopPropagation();
    setIsFontMenuOpen(!isFontMenuOpen);
  };

  const toggleRecommendation = (e) => {
    e.stopPropagation();
    if (hasRecommendation) {
      setIsRecommendationOpen(!isRecommendationOpen);
    }
  };

  const closeRecommendation = () => {
    setIsRecommendationOpen(false);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        // Don't close if clicking on tutorial bubble
        if (!event.target.closest('.tutorial-bubble')) {
          setIsOpen(false);
        }
      }
      if (
        recommendationRef.current &&
        !recommendationRef.current.contains(event.target)
      ) {
        setIsRecommendationOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [setIsOpen]);

  const truncateEmail = (email) => {
    const [username] = email.split("@");
    return username;
  };

  return (
    <div className="user-section" ref={menuRef}>
      <div
        className={`status-indicator ${
          isConnected ? "connected" : "disconnected"
        }`}
      >
        {isConnected ? "Connected" : "Disconnected"}
      </div>
      <div className="user-info" onClick={toggleUserMenu}>
        <div className="user-avatar">
          <User size={20} />
        </div>
        <span className="userEmail">{truncateEmail(userEmail)}</span>
        <div className="notification-icon" onClick={toggleRecommendation}>
          <Bell size={16} />
          {hasRecommendation && <span className="notification-dot"></span>}
        </div>
        {isOpen ? <ChevronUp size={16} /> : <ChevronDown size={16} />}
      </div>

      {isRecommendationOpen && recommendationContent && (
        <div className="recommendation-popup-wrapper">
          <div className="recommendation-popup" ref={recommendationRef}>
            <button
              className="close-recommendation"
              onClick={closeRecommendation}
            >
              <X size={16} />
            </button>
            <h4>Notifications</h4>
            <p>
              <strong>{recommendationContent.product_name}</strong>:{" "}
              {recommendationContent.notification}
            </p>
          </div>
        </div>
      )}

      <div className={`user-menu upward ${isOpen ? "open" : ""}`}>
        <div className="menu-header">
          <div className="user-avatar large">
            <User size={30} />
          </div>
          <div className="user-details">
            <span className="user-email">{userEmail}</span>
          </div>
        </div>
        <div className="menu-items">
          <div className="menu-item" onClick={toggleFontMenu}>
            <Type size={16} />
            <span>Font</span>
            {isFontMenuOpen ? <ChevronUp size={16} /> : <ChevronDown size={16} />}
          </div>
          <div className={`submenu ${isFontMenuOpen ? "open" : ""}`}>
            <div
              className="menu-item"
              onClick={() => onFontChange("Arial, sans-serif")}
            >
              <Type size={16} />
              <span>Arial (Default)</span>
            </div>
            <div
              className="menu-item"
              onClick={() => onFontChange("'Inter', sans-serif")}
            >
              <Type size={16} />
              <span>Inter</span>
            </div>
            <div
              className="menu-item"
              onClick={() => onFontChange("'Roboto', sans-serif")}
            >
              <Type size={16} />
              <span>Roboto</span>
            </div>
            <div
              className="menu-item"
              onClick={() => onFontChange("'Open Sans', sans-serif")}
            >
              <Type size={16} />
              <span>Open Sans</span>
            </div>
            <div
              className="menu-item"
              onClick={() => onFontChange("'Montserrat', sans-serif")}
            >
              <Type size={16} />
              <span>Montserrat</span>
            </div>
          </div>
          <div className="menu-item id-item">
            <span>
              <Fingerprint
                size={16}
                style={{ marginRight: "11px", verticalAlign: "middle" }}
              />
              Your ID:
            </span>
            <CopyableIdBox id={checkAssociatedCid} maxLength={12} />
          </div>
          <div className="menu-item" onClick={onLogout}>
            <LogOut size={16} />
            <span>Log Out</span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UserMenu;