import React from "react";
import ReactMarkdown from "react-markdown";
import { ArrowRight } from "lucide-react";
import FeedbackButtons from "../FeedbackButtons";
import userIcon from "../../assets/user-icon.png";
import gptImgLogo from "../../assets/Untitled.png";
import "../ChatMessage.css";

const ChatMessage = ({
  message,
  index,
  currentStepIndices,
  onHover,
  onLeaveHover,
  onNextStep,
  onFeedback,
}) => {
  return (
    <div
      className={`chat ${message.isBot ? "bot" : "user"}`}
      onMouseEnter={() => onHover(index)}
      onMouseLeave={() => onLeaveHover(index)}
    >
      {message.isBot ? (
        <>
          <img src={gptImgLogo} className="chatimg" alt="" />
          <div className="txt">
            <div className="message-content">
              {message.diagnosis && (
                <div className="diagnosis-section">
                  <p className="diagnosis">{message.diagnosis}</p>
                  {message.actionSteps && message.actionSteps.length > 0 && (
                    <p className="action-intro">
                      Let's follow{" "}
                      {message.actionSteps.length > 1
                        ? "these steps"
                        : "this step"}{" "}
                      to address the issue:
                    </p>
                  )}
                </div>
              )}
              {message.actionSteps && message.actionSteps.length > 0 ? (
                <div className="action-steps-container">
                  {message.actionSteps.length === 1 ? (
                    <p className="action-step">{message.actionSteps[0]}</p>
                  ) : (
                    message.actionSteps
                      .slice(0, currentStepIndices[index] + 1 || 1)
                      .map((step, stepIndex) => (
                        <p key={stepIndex} className="action-step">
                          <strong>Step {stepIndex + 1}:</strong> {step}
                        </p>
                      ))
                  )}
                  {message.actionSteps.length > 1 &&
                    (currentStepIndices[index] + 1 || 1) <
                      message.actionSteps.length && (
                      <button
                        onClick={() => onNextStep(index)}
                        className="next-step-btn"
                      >
                        Next step
                        <ArrowRight size={16} />
                      </button>
                    )}
                </div>
              ) : (
                <ReactMarkdown>{message.text || ""}</ReactMarkdown>
              )}
            </div>
            {index !== 0 && (
              <FeedbackButtons
                messageId={index}
                onFeedback={onFeedback}
                messageText={message.text || ""}
              />
            )}
          </div>
        </>
      ) : (
        <>
          <div className="txt">
            <div className="message-content">
              <ReactMarkdown>{message.text || ""}</ReactMarkdown>
            </div>
          </div>
          <img src={userIcon} className="chatimg" alt="" />
        </>
      )}
    </div>
  );
};

export default ChatMessage;
